export const textTracks = {
  "ep-1": {
    src: "https://cc.2cdns.com/8b/38/8b389cc9c5750573c0614c7f2bff19af/eng-2.vtt", 
    label: "English",
    language: "en-US",
    kind: "subtitles",
    type: "vtt",
    default: true,
  },
  "ep-2": {
    src: "https://cc.2cdns.com/94/a4/94a492a9f69c97d0b33d58ba6ff44d96/eng-2.vtt",
    label: "English",
    language: "en-US",
    kind: "subtitles",
    type: "vtt",
    default: true,
  },
  "ep-3": {
    src: "https://cc.2cdns.com/24/e4/24e4d1eb65cee23790a0ea54903b918a/eng-2.vtt", 
    label: "English",
    language: "en-US",
    kind: "subtitles",
    type: "vtt",
    default: true,
  },
  "ep-4": {
    src: "https://cc.2cdns.com/b1/bc/b1bcdb1606c84e037a975aea91536552/eng-2.vtt",
    label: "English",
    language: "en-US",
    kind: "subtitles",
    type: "vtt",
    default: true,
  },
  "ep-5": {
    src: "https://cc.2cdns.com/f3/90/f39040f10f5834797b14cd9b8067bf4a/eng-2.vtt", 
    label: "English",
    language: "en-US",
    kind: "subtitles",
    type: "vtt",
    default: true,
  },
  "ep-6": {
    src: "https://cc.2cdns.com/d7/d5/d7d5f604db1b47fc580c69370ea254fe/eng-2.vtt",
    label: "English",
    language: "en-US",
    kind: "subtitles",
    type: "vtt",
    default: true,
  },
  "ep-7": {
    src: "https://cc.2cdns.com/c1/21/c121a7210a8d7110ca6fd9506cae96a5/eng-2.vtt", 
    label: "English",
    language: "en-US",
    kind: "subtitles",
    type: "vtt",
    default: true,
  },
  "ep-8": {
    src: "https://cc.2cdns.com/53/d1/53d18b77db725127303ed12fe738aac7/eng-2.vtt", 
    label: "English",
    language: "en-US",
    kind: "subtitles",
    type: "vtt",
    default: true,
  },
  "ep-9": {
    src: "https://cc.2cdns.com/1b/21/1b2188aeac4af3c27971de34c39b8295/eng-34.vtt", 
    label: "English",
    language: "en-US",
    kind: "subtitles",
    type: "vtt",
    default: true,
  },
  "ep-10": {
    src: "https://cc.2cdns.com/73/12/73126b4e66c39f9bf5b565f475ed72c7/eng-34.vtt", 
    label: "English",
    language: "en-US",
    kind: "subtitles",
    type: "vtt",
    default: true,
  },
  "ep-11": {
    src: "https://cc.2cdns.com/82/3e/823e165d5a2ecfc03aacbed7b63bb3e2/eng-2.vtt", 
    label: "English",
    language: "en-US",
    kind: "subtitles",
    type: "vtt",
    default: true,
  },
  "ep-12": {
    src: "https://cc.2cdns.com/51/42/5142d4f0792b44d529fa7a657700b79c/eng-34.vtt", 
    label: "English",
    language: "en-US",
    kind: "subtitles",
    type: "vtt",
    default: true,
  },
  "ep-13": {
    src: "https://cc.2cdns.com/ca/c1/cac177d70bab6f168c85f401b09208b6/eng-2.vtt", 
    label: "English",
    language: "en-US",
    kind: "subtitles",
    type: "vtt",
    default: true,
  },
  "ep-14": {
    src: "https://cc.2cdns.com/c1/ae/c1aeeec553f659749659423deae6c467/eng-2.vtt", 
    label: "English",
    language: "en-US",
    kind: "subtitles",
    type: "vtt",
    default: true,
  },
  "ep-15": {
    src: "https://cc.2cdns.com/45/cb/45cb6742e48979ad144f8629bd986497/eng-2.vtt", 
    label: "English",
    language: "en-US",
    kind: "subtitles",
    type: "vtt",
    default: true,
  },
  "ep-16": {
    src: "https://cc.2cdns.com/5d/bd/5dbdd2b9c97f6e73aa0350738c76ab5c/eng-3.vtt", 
    label: "English",
    language: "en-US",
    kind: "subtitles",
    type: "vtt",
    default: true,
  },
};
